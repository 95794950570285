import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modalremoval"
export default class extends Controller {

  static targets = ["close"]

  connect() {
    let modals = document.querySelectorAll('.modal')
    if(modals !== null){
      modals.forEach(modal => {
        if (modal !== this.element){
          modal.remove();
        }
      });
    }
  }

  close() {
    this.element.remove();
  }
}
