import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (!navigator.clipboard) {
      console.warn("Clipboard API is not available in this browser.")
      return; 
    }
  }

  copy(event) {
    event.preventDefault(); 

    const linkUrl = this.element.href;  

    if (!linkUrl) {
      console.error("Link URL is missing.");
      return;
    }

    navigator.clipboard.writeText(linkUrl)
      .then(() => {
        const originalText = this.element.innerHTML; 
        this.element.textContent = "Copied!"; 
        setTimeout(() => {
            this.element.innerHTML = originalText; 
        }, 2000); 

      })
      .catch(err => {
        console.error("Failed to copy: ", err);
        this.element.textContent = "Copy Failed!";
        setTimeout(() => {
            this.element.textContent = originalText; 
        }, 2000); 
      });
  }
}
